<template>
    <div style="background: rgba(242,246,250,.9);position:fixed;top:0;right:0;bottom:0;left:0;z-index:110;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
        <div v-click-away="toggleChangeEmail" style="padding:40px 0;border-radius:10px;min-width:200px;min-height:200px;background:#fff;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">

        <div style="padding:25px;min-width:350px;max-width:400px;">

             <div style="margin-bottom:15px;display:flex;flex-direction:column;">
               <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;">New Email</div>
               
               <div style="display:flex;align-items:center;border-radius:6px;">
                    <input v-model="form.email" class="input-field" style="border:none;display:flex;justify-content:center;align-items:center;height:50px;padding:14px 20px;width:100%;background-color:rgba(242,246,250,.9);border-radius:6px;font-size:14px;font-weight:600;" type="text" /> 
               </div>
             </div>

                <div style="margin-bottom:15px;display:flex;flex-direction:column;">
                    <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;">Confirm</div>
                
                    <div style="display:flex;align-items:center;border-radius:6px;">
                        <input v-model="form.confirm" class="input-field" style="border:none;display:flex;justify-content:center;align-items:center;height:50px;padding:14px 20px;width:100%;background-color:rgba(242,246,250,.9);border-radius:6px;font-size:14px;font-weight:600;" type="text" /> 
                    </div>
                </div>
                
                <div @click.prevent="changeEmail" style="padding:20px;cursor:pointer;text-align:center;color:#202d4a;font-size:18px;font-weight:600;">Change</div>

        </div>

        </div>
    </div>
</template>

<script>
import { fetch } from '@/helpers'
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            form: {
               email: null,
               confirm: null,
               id: null
            }
        }
    },
    created(){
        this.form.id = this.user._id
    },
    methods: {
        toggleChangeEmail(){
          this.$emit('toggleChangeEmail')  
        },
        changeEmail() {
            if(this.form.email !== this.form.confirm){
                this.$toast('Email does not match')
                return;
            }
            fetch('auth/changeEmail', this.form)
            .then(() => {
                this.$emit('toggleChangeEmail')
                this.$toast('Email Changed')
            })
            .catch((err) => this.$toast(err))
        }
    }
}
</script>

<style scoped>
    .menu-item {
        background-color: #fff;
        transition: .2s;
    }

    .menu-item:hover {
        background-color: rgba(242,246,250,.9);
    }
</style>