<template>
    <div style="background: rgba(242,246,250,.9);position:fixed;top:0;right:0;bottom:0;left:0;z-index:110;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
        <div v-click-away="toggleCrmMenu" style="padding:40px 0;border-radius:10px;min-width:200px;min-height:200px;background:#fff;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">
       
        <div v-show="!deleteOpen && !priorityOpen" @click.prevent="toggleDelete" class="menu-item" style="cursor:pointer;padding:25px;">
            <div style="text-align:center;color:#f64f59;font-size:18px;font-weight:600;">Delete</div>
        </div>

        <div v-show="deleteOpen && !priorityOpen" style="padding:25px;max-width:400px;">
            <div style="text-align:center;padding:20px;">
                <div>Are you sure you want to delete <strong>{{ crm.name }}</strong>? this cannot be undone.</div>
            </div>
             <div @click.prevent="deleteCrm" style="cursor:pointer;text-align:center;color:#f64f59;font-size:18px;font-weight:600;">Confirm Delete</div>
        </div>

        <div v-show="!deleteOpen && !priorityOpen" @click.prevent="togglePriority" class="menu-item" style="cursor:pointer;padding:30px;">
            <div style="text-align:center;color:#202d4a;font-size:18px;font-weight:600;">Priority</div>
        </div>

        <div v-show="!deleteOpen && priorityOpen" style="padding:30px;max-width:300px;">

             <div style="display:flex;flex-direction:column;">
               <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;text-align:center;">Set priority</div>
               
               <div style="display:flex;align-items:center;border-radius:6px;justify-content:center;">
                    <!--<input v-model="form.priority" class="input-field" style="border:none;display:flex;justify-content:center;align-items:center;height:50px;padding:14px 20px;width:100%;background-color:rgba(242,246,250,.9);border-radius:6px;font-size:14px;font-weight:600;" type="text" /> -->
             
                <select v-model="form.priority" @change="selectPriority($event)" style="border:none;display:flex;justify-content:center;align-items:center;height:50px;padding:14px 20px;width:100px;background-color:rgba(242,246,250,.9);border-radius:6px;font-size:14px;font-weight:600;">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
             
               </div>
             </div>
             <div @click.prevent="updatePriority" style="padding:20px;cursor:pointer;text-align:center;color:#202d4a;font-size:18px;font-weight:600;">Update</div>

                
        </div>
      

        </div>
    </div>
</template>

<script>
import { fetch } from '@/helpers'

export default {
    props: {
        crm: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            deleteOpen: false,
            priorityOpen: false,
            form: {
               priority: null,
               id: null
            }
        }
    },
    created(){
        this.form.id = this.crm._id
        this.form.priority = this.crm.priority
    },
    methods: {
        selectPriority(event){
            this.form.priority = event.target.value
        },
        toggleCrmMenu(){
          this.$emit('toggleCrmMenu')  
        },
        toggleDelete(){
             this.deleteOpen = true
             this.priorityOpen = false
            
        },
        togglePriority(){
            this.deleteOpen = false
            this.priorityOpen = true
        },
        deleteCrm() {
            fetch('crms/delete', this.crm._id)
            .then(() => {
                this.$emit('toggleCrmMenu')
                this.$toast('Crm Deleted')
            })
            .catch((err) => this.$toast(err))
        },
        updatePriority() {
            fetch('crms/priority', this.form)
            .then(() => {
                this.$emit('toggleCrmMenu')
                this.$toast('Priority Updated')
            })
            .catch((err) => this.$toast(err))
        }
    }
}
</script>

<style scoped>
    .menu-item {
        background-color: #fff;
        transition: .2s;
    }

    .menu-item:hover {
        background-color: rgba(242,246,250,.9);
    }
</style>