import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

const URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {
    items: [],
    item: {}
  },
  actions: {
    read ({ state, commit }) {
      commit('setItems', { resource: 'customers', items: []}, {root: true}) //reset state

      return axiosInstance.get(`${URL}/customers`)
        .then(res => {
          const customers = res.data
          commit('setItems', { resource: 'customers', items: customers}, {root: true})
          return state.items
        })
    },
    findone ({ state, commit }, cusId){
      commit('setItem', {resource: 'customers', item: {}}, {root: true})
      return axiosInstance.get(`${URL}/customers/${cusId}`)
      .then(res => {
        const customer = res.data
        commit('setItem', {resource: 'customers', item: customer}, {root: true})
        return state.item
      })
    },
      rollkey({commit}, data){
        return axiosInstance.patch(`${URL}/customers/rollkey`, data)
            .then(res => {
               commit('updateKey', res.data)
               return true
            })
            .catch(err => rejectError(err))
      },
      status({commit}, data){
        return axiosInstance.patch(`${URL}/customers/status`, data)
            .then(res => {
               commit('updateStatus', res.data)
               return true
            })
            .catch(err => rejectError(err))
      },
      recording({commit}, data){
        return axiosInstance.patch(`${URL}/customers/recording`, data)
            .then(res => {
               commit('updateRecording', res.data)
               return true
            })
            .catch(err => rejectError(err))
      },
      create({state, commit}, data) {
        return axiosInstance.post(`${URL}/customers/create`, data)
          .then(res => {
            var createdCustomer = res.data
            const index = state.items.length
            commit('addItemToArray', {item: createdCustomer, index: index})
            return true
          })
          .catch(err => rejectError(err))
      },
    update({commit}, data) {
      return axiosInstance.patch(`${URL}/customers/update`, data)
        .then(res => {
          var customer = res.data
          commit('update', customer)
          return true
        })
        .catch(err => rejectError(err))
    },
    delete({commit, state}, cusId) {
      return axiosInstance.delete(`${URL}/customers/delete/${cusId}`)
        .then((res) => {
          const id = res.data
          const index = state.items.findIndex(customer => customer._id === id)
          if (index > -1) {
            commit('deleteCustomer', { index: index })
          }
          return true
        })
      .catch(err => rejectError(err))
    },
  },
  mutations: {
    update(state, data){
      state.item = data
    },
    updateKey(state, data){
      state.item.key = data
    },
    updateStatus(state, data){
      state.item.enabled = data
    },
    updateRecording(state, data){
      state.item.recording = data
    },
    deleteCustomer(state, {index}) {
      state.items.splice(index, 1)
    },
    addItemToArray(state, data){
      state.items.push(data.item)
    }
  }
}
