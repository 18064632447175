import axios from 'axios'
import jwt from 'jsonwebtoken'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

const URL = process.env.VUE_APP_URL

function checkTokenValidity (token) {
  if (token) {
    const decodedToken = jwt.decode(token)
    return decodedToken && (decodedToken.exp * 1000) > new Date().getTime()
  }
  return false
}

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {
    authUser (state) {
      return state.user || null
    },
    isAuthenticated (state) {
      return !!state.user
    },
    isAdmin (state) {
      if(state.user) {
        return state.user.pl
      }
      return false
    },
  },
  actions: {
    login ({commit}, userData) {
      return axios.post(`${URL}/users/login`, userData, { timeout: process.env.VUE_APP_TIMEOUT })
        .then(res => {
          const user = res.data
          localStorage.setItem('middleware', user.token)
          commit('setAuthUser', user)
        })
        .catch(err => rejectError(err))
    },
    updateAuthUser({commit}, updatedUser) {
      commit('setAuthUser', updatedUser)
      return updatedUser
    },
    // registerUser (userData){
    //   return axios.post(`${URL}/users/register`, userData)
    //     .catch(err => rejectError(err))
    // },
    getAuthUser ({commit, getters}) {
      const authUser = getters['authUser']
      const token = localStorage.getItem('middleware')

      if (!token) {
        commit('setAuthUser', null)
        commit('setAuthState', true)
        return false
      }
      const isTokenValid = checkTokenValidity(token)

      if (authUser && isTokenValid) { return Promise.resolve(authUser) }

      const config = {
        headers: {
          'Cache-Control': 'no-cache'
        }
      }

      return axiosInstance.get(`${URL}/users/me`, config, { timeout: process.env.VUE_APP_TIMEOUT })
        .then((res) => {
          const user = res.data
          localStorage.setItem('middleware', user.token)
          commit('setAuthUser', user)
          commit('setAuthState', true)
          return user
        })
        .catch(err => {
          commit('setAuthUser', null)
          commit('setAuthState', true)
        })
    },
    logout({commit}) {
      return new Promise((resolve) => {
        localStorage.removeItem('middleware')
        commit('setAuthUser', null)
        resolve(true)
      })
    },
    changeEmail({commit}, data){
      return axiosInstance.patch(`${URL}/users/email`, data)
          .then(res => {
             commit('updateEmail', res.data)
             return true
          })
          .catch(err => rejectError(err))
    },
    changePassword(_, data){
      return axiosInstance.patch(`${URL}/users/password`, data)
          .then(res => {
             return true
          })
          .catch(err => rejectError(err))
    },
  },
  mutations: {
    setAuthUser(state, user) {
      return state.user = user
    },
    setAuthState(state, authState) {
        return state.isAuthResolved = authState
    },
    updateEmail(state, data){
      state.user.email = data
    },
    }
  }
