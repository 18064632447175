import store from "@/store"

export const rejectError = ({response = null}) => {
  let message = 'Ooops, something went wrong'

  if (response && response.data && response.data.errors) {
    message = response.data.errors.message
  }

  return Promise.reject(message)
}


export const applyFilters = (url, filter) => {
  if (filter) {
    let filteredEntities = ''
    if (url.indexOf('?') === -1) {
      url += '?'
    } else {
      url += '&'
    }

    Object.keys(filter).forEach(key => {
      filteredEntities += `${key}=${filter[key]}&`;
    })

    if (filteredEntities.slice(-1) === '&') {
      filteredEntities = filteredEntities.slice(0, -1)
    }
    return url + filteredEntities
  }
  return url
}

export const fetch = (url, data) => {
  return new Promise((resolve, reject) => {
    var clicked = false
    if(clicked) {
        console.log('user spamming')
    } else {
        clicked = true
        store.dispatch(url, data)
        .then((res) => {
            clicked = false
            resolve(res)
            })
        .catch((err) => {
            clicked = false
            reject(err)
        })
    }
  })
    
    
}
