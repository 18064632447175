<template>
    <div style="background: rgba(242,246,250,.9);position:fixed;top:0;right:0;bottom:0;left:0;z-index:110;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
        <div v-click-away="toggleLogout" style="padding:40px 0;border-radius:10px;min-width:200px;min-height:200px;background:#fff;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">

        <div style="padding:25px;max-width:400px;">
            <div style="text-align:center;padding:20px;">
                <div>Are you sure you want to logout?</div>
            </div>
             <div @click.prevent="logout" style="cursor:pointer;text-align:center;color:#202d4a;font-size:18px;font-weight:600;">Logout</div>
        </div>

        </div>
    </div>
</template>

<script>
import { fetch } from '@/helpers'
export default {
    methods: {
        toggleLogout(){
          this.$emit('toggleLogout')  
        },
        logout() {
        fetch('auth/logout')
        .then(() => {
            window.location.href = "/"
        })
     }
    }
}
</script>

<style scoped>
</style>