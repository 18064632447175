<template>
    <div style="display:flex;justify-content:center;">
        <div style="display:flex;flex-direction:column;">
            <div style="align-self:center;margin: 50px 0 100px 0;background-color:#202d4a;color:#fff;width:160px;padding:3px;">
                <div style="font-size:18px;font-weight:700;text-align:center;">3CX Middleware</div>
            </div>

        <div style="width:300px;margin-top:25px;">
            <div style="margin-bottom:10px;">
                 <input v-model="form.email" @keyup.enter.exact="login" @keydown.enter.exact.prevent placeholder="Email" type="text" maxlength="50" style="font-size:16px;font-weight:500;padding: 10px 20px;height:50px;border-radius:5px;border:none;background-color:#f2f6fa;width:100%;" />
            </div>

            <div style="margin-bottom:30px;">
                <input v-model="form.password" @keyup.enter.exact="login" @keydown.enter.exact.prevent placeholder="Password" type="password" maxlength="50" style="font-size:16px;font-weight:500;padding: 10px 20px;height:50px;border-radius:5px;border:none;background-color:#f2f6fa;width:100%;" />
            </div>

            <div @click.prevent="login" style="padding:20px;cursor:pointer;text-align:center;color:#202d4a;font-size:18px;font-weight:600;">Sign in</div>
        </div>
            
        </div>
    </div>
</template>
<script>
import { fetch } from '@/helpers'
export default {
    data(){
        return {
        clicked: false,
            form: {
                email: null,
                password: null
            }
        }
    },
    methods: {
        login () {
            fetch('auth/login', this.form)
            .then(() => {
                if(this.$cookies.get('redirect')) {
                    var cookie = this.$cookies.get('redirect')
                    //console.log(cookie)
                    this.$cookies.remove('redirect')
                    window.location.href = `/${cookie.link}/?code=${cookie.code}`
                } else {
                    window.location.href = "/"
                }
                
            })
            .catch((err) => this.$toast(err))
        }
    }
}
</script>

<style scoped>

</style>