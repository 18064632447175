<template>
  <div id="app" style="height:calc(100vh - 60px);">
    <Top v-if="$route.name !== 'Login' && loaded && $route.name !== 'Redirect' && $route.name !== 'Auth'" />
    <router-view v-if="loaded" :key="$route.path" />
  </div>
</template>

<script>
import Top from '@/components/Top.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    Top
  },
  data(){
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      'user': 'auth/authUser'
    }),
  },
  created(){
    this.$store.dispatch('auth/getAuthUser')
    .then(()  => {
      this.loaded = true
    })
  },
}
</script>

<style>
* {
  outline: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 14px;
  line-height: 22px;
}

a {
  text-decoration: none;
  color: inherit;
}

</style>
