<template>
  <main>
 
  </main>
</template>

<script>
import { fetch } from '@/helpers'
export default {
    created(){

        if(this.$cookies.get('auth')){
            var cookie = this.$cookies.get('auth')
            this.$cookies.remove('auth')
            var query = this.$route.query
            var data = Object.assign(cookie, query);

            fetch('crms/authenticate', data)
                .then(() => {
                    window.open(`/customer/${cookie.customer}`, '_self')
                })
                .catch((err) => this.$toasted.error(err, {position: "bottom-center", duration: 5000}))

        } else {
            window.location.href = '/'
        }
    }

}
</script>

<style scoped>
</style>
