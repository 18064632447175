<template>
    <main style="overflow-y:auto;height:100%;">
        <div v-if="loaded && crmsLoaded" style="width: 100%;max-width: 1220px;margin: 0 auto 180px;">
            
            <div style="display:flex;">
            <div>

                <div style="margin-top:100px;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
                    <div style="margin-bottom: 20px;width:480px;color:#202d4a;font-size:18px;font-weight:700;">Customer</div>
                    <div style="position:relative;padding:40px;border-radius:8px;width:480px;background:#f2f6fa;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">

                    <div @click.prevent="toggleCustomerMenu" style="position:absolute;top:0;right:0;padding:20px 20px 0 0;cursor:pointer;">
                                <svg height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#4f566b" d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
                                </svg>
                            </div>
                
                        <div style="margin-bottom:5px;display:flex;align-items:center;min-height:42px;">
                            <div style="width:130px;color:#202d4a;font-size:16px;font-weight:600;">Name</div>
                            <div class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                                <div>{{ customer.name }}</div>
                            </div>
                        </div>

                        <div style="display:flex;align-items:center;min-height:42px;">
                            <div style="width:130px;color:#202d4a;font-size:16px;font-weight:600;">3CX server URL</div>
                            <div v-clipboard:copy="customer.server" @copied="onCopy" class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                                <div v-if="customer.server" style="">{{ customer.server }}</div>
                                <div v-else style="color:#8d95a6;">No details</div>
                            </div>
                        </div>
                </div>
            </div>

            <div style="margin-top:70px;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
                    <div style="margin-left:60px;" class="toggle-switch">
                            <div @click.prevent="toggleApi" class="toggle" :class="{ active: customer.enabled }"></div>
                            <div style="width:480px;color:#202d4a;font-size:18px;font-weight:700;">Enable API</div>
                    </div>
                    <div style="margin-left:60px;" class="toggle-switch">
                        <div @click.prevent="toggleRecordings" class="toggle" :class="{ active: customer.recording }"></div>
                        <div style="width:480px;color:#202d4a;font-size:18px;font-weight:700;">Enable Recordings</div>
                    </div>


                    <div style="padding:40px;border-radius:8px;width:480px;background:#f2f6fa;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">
                
                        <div @click.prevent="toggleRollKey" style="cursor:pointer;margin-bottom:30px;display:flex;align-items:center;">
                            <div style="color:#8d95a6;font-size:14px;">Generate new key</div>
                        </div>

                        <div style="display:flex;align-items:center;">
                            <div style="width:100px;color:#202d4a;font-size:16px;font-weight:600;">API Key</div>
                            <div v-clipboard:copy="customer.key" @copied="onCopy" class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                                <div style="color:#202d4a;font-size:16px;font-weight:600;">{{ customer.key }}</div>
                            </div>
                        </div>
                </div>
            </div>

            <div style="margin-top:70px;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
                    <div style="margin-bottom: 20px;width:480px;color:#202d4a;font-size:18px;font-weight:700;">Download XML File</div>
                    <div style="padding:40px;border-radius:8px;width:480px;background:#f2f6fa;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">
                
                    <div style="display:flex;">
                            <div @click.prevent="xml" class="download-button" style="background-color:#3399ff;padding: 15px 30px;border-radius:6px;cursor:pointer;">
                                <div style="color:#fff;font-size:15px;font-weight:600;">Download</div>
                            </div>

                            <div style="margin-left:30px;display:flex;align-items:center;">
                                <div style="color:#8d95a6;font-size:14px;">Download this XML file and upload it to 3CX.</div>
                            </div>
                    </div>
                </div>
            </div>

            </div>
            <div style="margin-left:30px;">
                <div v-if="crmsLoaded" style="margin-top:100px;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
                        <div style="display:flex;align-items:center;justify-content:space-between;margin-bottom: 20px;width:480px;">
                        <div style="color:#202d4a;font-size:18px;font-weight:700;">CRM Integrations</div>
                        <div @click.prevent="toggleNew" style="cursor:pointer;color:#202d4a;font-size:18px;font-weight:700;">Add new</div>
                        </div>

                    <div v-if="newCrm" style="margin-bottom:40px;padding:40px;border-radius:8px;width:480px;background:#f2f6fa;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">
                
                        <div style="margin-bottom:30px;display:flex;align-items:center;">
                            <div style="color:#8d95a6;font-size:14px;">Setup a new CRM</div>
                        </div>

                        <div style="position:relative">
                            <div style="margin-bottom:30px;display:flex;align-items:center;">
                                <div @click.prevent="toggleOptions" class="select" style="display:flex;justify-content:space-between;align-items:center;cursor:pointer;height:50px;padding:14px 20px;width:100%;background-color:#fff;border-radius:6px;">
                                    <div style="color:#202d4a;font-size:14px;font-weight:600;">{{ selectedCrm }}</div>
                                    <img style="height:6px;" src="https://cdn.side.nz/v/dc5d9a64-0150-47a3-9ca1-e557474bbc63.svg" />
                                </div>
                            </div>

                            <div v-if="optionsOpen" v-click-away="toggleOptions" style="box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);position:absolute;top:50px;left:0;width:100%;background-color:#fff;z-index:300;">
                                <div @click.prevent="toggleNewCrm('zoho')" class="select" style="cursor:pointer;padding:15px 20px;">Zoho</div>
                                <div @click.prevent="toggleNewCrm('servicem8')" class="select" style="cursor:pointer;padding:15px 20px;">ServiceM8</div>
                                <div @click.prevent="toggleNewCrm('pipedrive')" class="select" style="cursor:pointer;padding:15px 20px;">Pipedrive</div>
                                <div @click.prevent="toggleNewCrm('autotask')" class="select" style="cursor:pointer;padding:15px 20px;">Autotask</div>
                                <div @click.prevent="toggleNewCrm('halopsa')" class="select" style="cursor:pointer;padding:15px 20px;">HaloPSA</div>
                                <div @click.prevent="toggleNewCrm('netsuite')" class="select" style="cursor:pointer;padding:15px 20px;">Netsuite</div>
                                <div @click.prevent="toggleNewCrm('jobadder')" class="select" style="cursor:pointer;padding:15px 20px;">Jobadder</div>
                                <div @click.prevent="toggleNewCrm('bullhorn')" class="select" style="cursor:pointer;padding:15px 20px;">Bullhorn</div>
                                <div @click.prevent="toggleNewCrm('reamaze')" class="select" style="cursor:pointer;padding:15px 20px;">Reamaze</div>
                                <div @click.prevent="toggleNewCrm('cliniko')" class="select" style="cursor:pointer;padding:15px 20px;">Cliniko</div>
                            </div>
                        </div>

                        <div style="margin-bottom:0px;display:flex;align-items:center;">
                            <div style="color:#8d95a6;font-size:14px;">You can edit each integration later.</div>
                        </div>

                </div>


                    <IntegrationItem v-if="integrationSetup" :key="integrationName" :integration="integrationName" :customerId="customer._id" @toggleSetup="toggleSetup"/>
        
                    <div v-if="!noData">
                        <div v-for="crm in crms" :key="crm._id" style="position:relative;padding:40px;margin-bottom:40px;border-radius:8px;width:480px;background:#f2f6fa;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">
                            
                            <div @click.prevent="toggleCrmMenu(crm)" style="position:absolute;top:0;right:0;padding:20px 20px 0 0;cursor:pointer;">
                                <svg height="12" width="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#4f566b" d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path>
                                </svg>
                            </div>

                            <div style="display:flex;align-items:center;min-height:42px;">
                                <div style="width:130px;color:#202d4a;font-size:16px;font-weight:600;">Name</div>
                                <div class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                                    <div style="max-width: 220px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ crm.name }}</div>
                                </div>
                            </div>

                            <!-- <div style="display:flex;align-items:center;min-height:42px;">
                                <div style="width:130px;color:#202d4a;font-size:16px;font-weight:600;">Client Id</div>
                                <div v-clipboard:copy="crm.client_id" v-clipboard:success="onCopy" class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                                        <div style="max-width: 220px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ crm.client_id }}</div>
                                </div>
                            </div> -->

                        <div style="display:flex;align-items:center;min-height:42px;">
                                <div style="width:130px;color:#202d4a;font-size:16px;font-weight:600;">CRM</div>
                                <div class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                                        <div>{{ crm.type }}</div>
                                </div>
                            </div>

                            <div style="display:flex;align-items:center;min-height:42px;">
                                <div style="width:130px;color:#202d4a;font-size:16px;font-weight:600;">Priority</div>
                                <div class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                                        <div>{{ crm.priority }}</div>
                                </div>
                            </div>
                    </div>
                </div>
                <div v-else>
                <div style="padding:40px;border-radius:8px;width:480px;background:#f2f6fa;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">
                        <div style="display:flex;justify-content:center;align-items:center;min-height:42px;">
                            <div style="width:480px;color:#202d4a;font-size:18px;font-weight:700;text-align:center;">No CRM integrations</div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            </div>
        </div>
    <RollKey v-if="rollKeyOpen" @toggleRollKey="toggleRollKey" :customer="customer._id" />
    <CrmMenu v-if="menuOpen" @toggleCrmMenu="toggleCrmMenu" @toggleNew="toggleNew" :crm="crmData" />
    <CustomerMenu v-if="customerMenuOpen" @toggleCustomerMenu="toggleCustomerMenu" :customer="customerData" />
    </main>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import CrmMenu from '@/components/CrmMenu.vue'
import RollKey from '@/components/RollKey.vue'
import IntegrationItem from '@/components/IntegrationItem.vue'
import CustomerMenu from '@/components/CustomerMenu.vue'
import { fetch } from '@/helpers'
import { mapState } from 'vuex'
export default {
    components: {
        Spinner,
        CrmMenu,
        CustomerMenu,
        RollKey,
        IntegrationItem
    },
    data(){
        return {
            integrationSetup: false,
            integrationName: '',
            rollKeyOpen: false,
            optionsOpen: false,
            loaded: false,
            newCrm: false,
            crmsLoaded: false,
            crmLoading: false,
            selectedCrm: 'none',
            menuOpen: false,
            customerMenuOpen: false,
            crmData: {},
            customerData: {},
        }
    },
    computed: {
      ...mapState ({
        customer: state => state.customers.item,
        crms: state => state.crms.items
     }),
     noData(){
        if(Object.keys(this.crms).length === 0){
            return true
        } else {
            return false
        }
     },
   },
  created(){
    this.$store.dispatch('customers/findone', this.$route.params.id)
    .then(() => {
        this.$store.dispatch('crms/read', this.$route.params.id)
        .then((res) => {
            this.crmsLoaded = true
        })
        .catch((err) => {
            this.crmsLoaded = true
        })
        this.loaded = true
    })
  },
    methods: {
        toggleRollKey(){
            this.rollKeyOpen = !this.rollKeyOpen
        },
        toggleApi(){
            var form = {}
            form.customer = this.customer._id
            fetch('customers/status', form)
            .then(() => {

            })
            .catch((err) => this.$toast(err))
        },
        toggleRecordings(){
            var form = {}
            form.customer = this.customer._id
            fetch('customers/recording', form)
            .then(() => {

            })
            .catch((err) => this.$toast(err))
        },
        toggleNew(){
            this.$cookies.remove('auth'),
            this.integrationSetup = false
            this.integrationName = ''
            this.newCrm = !this.newCrm
        },
        toggleSetup(){
            this.integrationSetup = false
            this.selectedCrm = 'none'
        },
        toggleNewCrm(crm){
            this.toggleOptions()
            this.integrationSetup = false
            this.integrationSetup = true
            this.integrationName = crm
            this.selectedCrm = crm
        },
        xml(){
            window.open(`${process.env.VUE_APP_XML}`, '_blank')
        },
        toggleOptions(){
            this.optionsOpen = !this.optionsOpen
        },
        toggleCrmMenu(crm){
            this.crmData = crm
            this.menuOpen = !this.menuOpen
        },
        toggleCustomerMenu(){
            this.customerData = this.customer
            this.customerMenuOpen = !this.customerMenuOpen
        },
        onCopy(){
            this.$toast('Copied')
        }
    }
    
}
</script>

<style scoped>
input[type="text"]:disabled {
  color: #878e9e;
}

.input-field {
    color:#202d4a;
    transition: box-shadow 250ms;
}

.input-field:focus {
    box-shadow: 0 4px 8px rgba(32,45,74,.2);
}

.setting-item {
    background-color: #f2f6fa;
    transition: .2s;
}
.setting-item:hover {
    background-color:#e4e9ef;
}

.select {
    transition: box-shadow 250ms;
    box-shadow: 0 2px 4px rgba(32,45,74,.2);
}

.select:hover {
    transition: box-shadow 250ms;
    box-shadow: 0 4px 8px rgba(32,45,74,.2);
}

.download-button {
    opacity: 1;
    transition: .3s;
}

.download-button:hover {
    opacity: .75;
}

.toggle-switch {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 700;
    color: #202d4a;
}

.toggle {
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    transition-property: opacity,background-color;
    transition-duration: 125ms;
    height:26px;
    width:42px;
    padding:2px;
    background-color: rgba(11,23,48,.1);
    box-shadow: inset 0 0 0 1px rgba(11,23,48,.1);
    border-radius: 34px;
}

.toggle::after {
    content: '';
    display: block;
    height: 22px;
    width: 22px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(11,23,48,.2),0 0 0 1px rgba(11,23,48,.1);
    border-radius: 100%;
    transition: margin-left 250ms cubic-bezier(0,1,0,1);
}

.active {
    background-color: #52cc7a;
}

.toggle.active::after {
    margin-left: 16px;
}

.select {
    background-color:#fff;
    transition: .3s ease;
}

.select:hover {
    background-color:#f2f6fa;
}
</style>