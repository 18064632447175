<template>
  <header style="display:fixed;top:0;left:0;z-index:101;width:100%;box-shadow: 0 0 10px rgba(32,45,74,.2);">
    <div style="height:60px;display:flex;max-width:1220px;align-items: center;margin: auto;padding:0 10px;">
   
    <router-link :to="{ name: 'Dashboard' }" style="background-color:#202d4a;color:#fff;width:160px;padding:3px;">
        <div style="font-size:18px;font-weight:700;text-align:center;">3CX Middleware</div>
    </router-link>

    <div style="position: relative;display: flex;justify-content: flex-start;align-items: center;flex-grow: 1;margin-left: 20px;height: 60px;background: #fff;">
    </div>

    <div style="display: flex;flex-direction: row;flex-grow: 0;align-items: center;justify-content: center;margin-left: auto;">
      <router-link :to="{ name: 'Dashboard' }" class="nav-item" style="margin-right:20px;padding: 5px 12px;border-radius:6px;cursor:pointer;">
          <div style="color:#9096a5;font-size:15px;font-weight:600;">Customers</div>
      </router-link >
      <router-link :to="{ name: 'Settings' }" class="nav-item" style="margin-right:20px;padding: 5px 12px;border-radius:6px;cursor:pointer;">
          <div style="color:#9096a5;font-size:15px;font-weight:600;">Settings</div>
      </router-link>
      
      <div @click.prevent="toggleLogout" class="nav-item" style="padding: 5px 12px;border-radius:6px;cursor:pointer;">
          <div style="color:#9096a5;font-size:15px;font-weight:600;">Logout</div>
      </div>
    </div>
    </div>
    <Logout v-if="logoutOpen" @toggleLogout="toggleLogout"/>
  </header>
</template>

<script>
import Logout from '@/components/Logout.vue'
import { fetch } from '@/helpers'
export default {
  components: {
    Logout
  },
  data(){
    return {
      logoutOpen: false
    }
  },
  methods: {
    toggleLogout(){
      this.logoutOpen = !this.logoutOpen
    }
  }
  
}
</script>

<style scoped>
  .nav-item {
    background-color: #fff;
    transition: .2s;
  }

  .nav-item:hover {
    background-color: #f2f6fa;
  }
</style>