<template>
<div style="margin-bottom:50px;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
    <div style="margin-bottom: 20px;width:480px;color:#202d4a;font-size:18px;font-weight:700;">{{ loaddata.title }}</div>
        <div style="padding:40px;border-radius:8px;width:480px;background:#f2f6fa;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">

            <div v-for="(item, index) in loaddata.items" :key="index">

                <div v-if="item.type === 'info'" style="margin-bottom:30px;display:flex;align-items:center;">
                    <div style="color:#8d95a6;font-size:14px;">{{ item.message }}</div>
                </div>

                <div v-if="item.type === 'copy'" style="margin-bottom:25px;display:flex;flex-direction:column;">
                    <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;">{{ item.message }}</div>
                    <div v-clipboard:copy="item.copyItem" @copied="onCopy" style="display:flex;justify-content:space-between;align-items:center;cursor:pointer;height:50px;padding:14px 20px;width:100%;background-color:#fff;border-radius:6px;">
                        <div style="color:#202d4a;font-size:14px;font-weight:600;">{{ item.copyItem }}</div>
                    </div>
                </div> 

                <div v-if="item.type === 'input'" style="margin-bottom:15px;display:flex;flex-direction:column;">
                    <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;">{{ item.message }}</div>
                
                    <div class="input-container" style="display:flex;align-items:center;background-color:#fff;border-radius:6px;">
                        <input v-model="item.value" style="border:none;display:flex;justify-content:center;align-items:center;height:50px;padding:14px 20px;width:100%;background-color:#fff;border-radius:6px;font-size:14px;font-weight:600;" type="text" /> 
                        <svg v-if="item.value" style="height:20px;margin-right:15px;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#78ffd6" d="m9.707 19.121c-.187.188-.442.293-.707.293s-.52-.105-.707-.293l-5.646-5.647c-.586-.586-.586-1.536 0-2.121l.707-.707c.586-.586 1.535-.586 2.121 0l3.525 3.525 9.525-9.525c.586-.586 1.536-.586 2.121 0l.707.707c.586.586.586 1.536 0 2.121z"/>
                        </svg>
                    </div>

                </div>
                
            </div>

            <div v-if="proceed" style="display:flex;">
                <div v-if="!crmLoading" @click.prevent="createCrm" class="download-button" style="cursor:pointer;background-color:#3399ff;padding: 15px 30px;border-radius:6px;">
                    <div style="color:#fff;font-size:15px;font-weight:600;">Authorize</div>
                </div>
                <div v-else class="download-button" style="width:129px;height:52px;display:flex;align-items:center;justify-content:center;position:relative;background-color:#3399ff;padding: 15px 30px;border-radius:6px;">
                    <Spinner />
                </div>
            </div>
            <div v-else style="display:flex;">
                <div class="download-button" style="cursor:pointer;background-color:rgb(144, 150, 165);padding: 15px 30px;border-radius:6px;">
                    <div style="color:#fff;font-size:15px;font-weight:600;">Complete</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import { fetch } from '@/helpers'
export default {
    components: {
        Spinner
    },
    data() {
        return {
            crmLoading: false,
            loaddata: {
                title: '',
                auth: false,
                items: []
            },
            cliniko: {
                title: 'Cliniko',
                auth: false,
                items: [
                    { type: 'info', message: 'Generate an API key and enter the details below'},
                    { type: 'input', message: 'Account Id', key: 'account_id', value: ''},
                    { type: 'input', message: 'Shard', key: 'shard', value: ''},
                    { type: 'input', message: 'API Key', key: 'client_secret', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''}
                ]
            },
            reamaze: {
                title: 'Reamaze',
                auth: false,
                items: [
                    { type: 'info', message: 'Generate an API key and enter the details below'},
                    { type: 'input', message: 'Account Id', key: 'account_id', value: ''},
                    { type: 'input', message: 'Username', key: 'client_id', value: ''},
                    { type: 'input', message: 'API Key', key: 'client_secret', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''}
                ]
            },
            bullhorn: {
                title: 'Bullhorn',
                auth: true,
                items: [
                    { type: 'info', message: 'Register Bullhorn with this redirect URI'},
                    { type: 'copy', message: 'Redirect Uri', copyItem: process.env.VUE_APP_REDIRECT_URL},
                    { type: 'info', message: 'Enter the generated Bullhorn details below'},
                    { type: 'input', message: 'Client Id', key: 'client_id', value: ''},
                    { type: 'input', message: 'Client Secret', key: 'client_secret', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''},
                ]
            },
            jobadder: {
                title: 'Jobadder',
                auth: true,
                items: [
                    { type: 'input', message: 'Account Id', key: 'account_id', value: ''},
                    { type: 'info', message: 'Click Authorize then login with your job adder username and password'},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''},
                ]
            },
            netsuite: {
                title: 'Netsuite',
                auth: true,
                items: [
                    { type: 'info', message: 'Enter this data into Netsuite'},
                    { type: 'copy', message: 'Name', copyItem: process.env.VUE_APP_NAME},
                    { type: 'copy', message: 'Login Redirect URL', copyItem: process.env.VUE_APP_REDIRECT_URL},
                    { type: 'info', message: 'Enter the generated Netsuite Client ID below'},
                    { type: 'input', message: 'Account Id', key: 'account_id', value: ''},
                    { type: 'input', message: 'Client Id', key: 'client_id', value: ''},
                    { type: 'input', message: 'Client Secret', key: 'client_secret', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''},
                ]
            },
            halopsa: {
                title: 'HaloPSA',
                auth: true,
                items: [
                    { type: 'info', message: 'Enter this data into HaloPSA'},
                    { type: 'copy', message: 'Name', copyItem: process.env.VUE_APP_NAME},
                    { type: 'copy', message: 'Login Redirect URL', copyItem: process.env.VUE_APP_REDIRECT_URL},
                    { type: 'info', message: 'Enter the generated HaloPSA Client ID below'},
                    { type: 'input', message: 'Account Id', key: 'account_id', value: ''},
                    { type: 'input', message: 'Client Id', key: 'client_id', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''},
                ]
            },
            zoho: {
                title: 'Zoho',
                auth: true,
                items: [
                    { type: 'info', message: 'Enter this data into Zoho oauth'},
                    { type: 'copy', message: 'Client Name', copyItem: process.env.VUE_APP_NAME},
                    { type: 'copy', message: 'Homepage URL', copyItem: process.env.VUE_APP_ZOHO_HOMEPAGE_URL},
                    { type: 'copy', message: 'Authorized Redirect URIs', copyItem: process.env.VUE_APP_REDIRECT_URL},
                    { type: 'info', message: 'Enter the generated Zoho client ID and secret below'},
                    { type: 'input', message: 'Client ID', key: 'client_id', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''},
                ]
            },
            servicem8: {
                title: 'ServiceM8',
                auth: true,
                items: [
                    { type: 'info', message: 'Enter this data into Service M8'},
                    { type: 'copy', message: 'Name', copyItem: process.env.VUE_APP_NAME},
                    { type: 'copy', message: 'Callback URL', copyItem: process.env.VUE_APP_REDIRECT_URL},
                    { type: 'copy', message: 'Addon Activation URL', copyItem: process.env.VUE_APP_REDIRECT_URL},
                    { type: 'info', message: 'Enter the generated ServiceM8 App Id and App Secret below'},
                    { type: 'input', message: 'App Id', key: 'client_id', value: ''},
                    { type: 'input', message: 'App Secret', key: 'client_secret', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''},
                ]
            },
            pipedrive: {
                title: 'Pipedrive',
                auth: true,
                items: [
                    { type: 'info', message: 'Enter this data into Pipedrive'},
                    { type: 'copy', message: 'Name', copyItem: process.env.VUE_APP_NAME},
                    { type: 'copy', message: 'Callback URL', copyItem: process.env.VUE_APP_REDIRECT_URL},
                    { type: 'info', message: 'Enter the generated Pipedrive App Id and App Secret below'},
                    { type: 'input', message: 'App Id', key: 'client_id', value: ''},
                    { type: 'input', message: 'App Secret', key: 'client_secret', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''},
                ]
            },
            autotask: {
                title: 'Autotask',
                auth: false,
                items: [
                    { type: 'info', message: 'Enter this data into Autotask'},
                    { type: 'copy', message: 'Enter this as the first and last name field', copyItem: '3CX System'},
                    { type: 'info', message: 'Enter any email in the Email Address field'},
                    { type: 'info', message: 'Select API User(system) as the security level'},
                    { type: 'info', message: 'Select a location for the Primary Internal Location'},
                    { type: 'copy', message: 'Select Custom and enter the Internal Integration Name', copyItem: 'Aatrox 3CX Middleware'},
                    { type: 'info', message: 'Enter the generated Autotask Username(Key), Secret and Tracking Identifier below'},
                    { type: 'input', message: 'Username(Key)', key: 'client_id', value: ''},
                    { type: 'input', message: 'Secret', key: 'client_secret', value: ''},
                    { type: 'input', message: 'Tracking Identifier', key: 'tracking_id', value: ''},
                    { type: 'input', message: 'Resource Id', key: 'resource_id', value: ''},
                    { type: 'input', message: 'Integration Name', key: 'name', value: ''}
                ]
            },
            fields: {},
            app_name: process.env.VUE_APP_NAME,
            zoho_homepage_url: process.env.VUE_APP_ZOHO_HOMEPAGE_URL,
            zoho_redirect_url: process.env.VUE_APP_ZOHO_REDIRECT_URL,
            servicem8_redirect_url: process.env.VUE_APP_SERVICEM8_REDIRECT_URL,
            pipedrive_redirect_url: process.env.VUE_APP_PIPEDRIVE_REDIRECT_URL,
            halopsa_redirect_url: process.env.VUE_APP_HALOPSA_REDIRECT_URL,
            app_redirect_url: process.env.VUE_APP_REDIRECT_URL
        }
    },
    created(){
       if(this.integration === 'autotask') {
        this.loaddata = this.autotask
       } else if(this.integration === 'pipedrive') {
        this.loaddata = this.pipedrive
       } else if(this.integration === 'servicem8'){
        this.loaddata = this.servicem8
       } else if(this.integration === 'zoho'){
        this.loaddata = this.zoho
       } else if(this.integration === 'halopsa'){
        this.loaddata = this.halopsa
       } else if(this.integration === 'netsuite'){
        this.loaddata = this.netsuite
       } else if(this.integration === 'jobadder'){
        this.loaddata = this.jobadder
       } else if(this.integration === 'bullhorn'){
        this.loaddata = this.bullhorn
       } else if(this.integration === 'reamaze'){
        this.loaddata = this.reamaze
       } else if(this.integration === 'cliniko'){
        this.loaddata = this.cliniko
       }
    },
    props: {
        integration: {
            type: String,
            default: ''
        },
        customerId: {
            type: String,
            default: ''
        }
    },
    computed: {
        proceed(){
            var check = true
            for (var i = 0; i < this.loaddata.items.length; i++){
                if(this.loaddata.items[i].value === '') {
                    check = false
                    break;
                }
            }
            return check
        },
    },
    methods: {
        loadData(){
            return new Promise((resolve, reject) => {
                this.fields.type = this.integration
                this.fields.customer = this.customerId

                for (var i = 0; i < this.loaddata.items.length; i++){
                    if(this.loaddata.items[i].type === 'input') {
                        this.fields[this.loaddata.items[i].key] = this.loaddata.items[i].value
                    }    
                }
                resolve(true)
            })
        },
        async createCrm(){
            
            this.crmLoading = true
            await this.loadData()
            //console.log(this.fields)

            if(this.loaddata.auth){
                this.$cookies.set('auth', this.fields, '10min')
                var url = ''
                
                switch(this.integration) {
                    case 'pipedrive':
                    url = `https://oauth.pipedrive.com/oauth/authorize?client_id=${this.fields.client_id}&state=oauth&redirect_uri=${this.app_redirect_url}`
                    window.open(url, '_self')
                    break;
                    case 'servicem8':
                    url = `https://go.servicem8.com/oauth/authorize?response_type=code&client_id=${this.fields.client_id}&scope=manage_customers%20publish_job_notes%20manage_customer_contacts&redirect_uri=${this.app_redirect_url}`
                    window.open(url, '_self')
                    break;
                    case 'zoho':
                    url = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.ALL,ZohoCRM.users.ALL&client_id=${this.fields.client_id}&response_type=code&access_type=offline&redirect_uri=${this.app_redirect_url}`
                    window.open(url, '_self')
                    break;
                    case 'halopsa':
                    url = `https://aatrox.halopsa.com/auth/authorize?tenant=aatrox&client_id=${this.fields.client_id}&response_type=code&scope=all&redirect_uri=${this.app_redirect_url}`
                    window.open(url, '_self')
                    break;
                    case 'netsuite':
                    url = `https://${this.fields.account_id}.app.netsuite.com/app/login/oauth2/authorize.nl?scope=restlets+rest_webservices&redirect_uri=${this.app_redirect_url}&response_type=code&client_id=${this.fields.client_id}&state=ykv2XLx1BpT5Q0F3MRPHb94j`
                    window.open(url, '_self')
                    break;
                    case 'jobadder':
                    url = `https://id.jobadder.com/connect/authorize?response_type=code&client_id=${process.env.VUE_APP_JOBADDER_CLIENT_ID}&redirect_uri=${this.app_redirect_url}&scope=read write offline_access` 
                    window.open(url, '_self')
                    break;
                    case 'bullhorn':
                    url = `https://auth.bullhornstaffing.com/oauth/authorize?client_id=${this.fields.client_id}&response_type=code&redirect_uri=${this.app_redirect_url}&state=ykv2XLx1BpT5Q0F3MRPHb94j`
                    window.open(url, '_self')
                }
                
            } else if(!this.loaddata.auth){
                fetch('crms/createcrm', this.fields)
                .then((res) => {
                    this.$emit('toggleSetup')
                    this.$emit('toggleNew')
                    this.crmLoading = false
                    this.$toast('Crm added')
                })
                .catch((err) => {
                    this.crmLoading = false
                    this.$toast(err)
                })
            }  
        },
        onCopy(){
          this.$toast('Copied')
        }
    }
}
</script>

<style scoped>
input[type="text"]:disabled {
  color: #878e9e;
}

.input-container:focus-within {
    box-shadow: 0 4px 8px rgba(32,45,74,.2);
}

.input-container {
    color:#202d4a;
    transition: box-shadow 250ms;
}

.setting-item {
    background-color: #f2f6fa;
    transition: .2s;
}
.setting-item:hover {
    background-color:#e4e9ef;
}

.select {
    transition: box-shadow 250ms;
    box-shadow: 0 2px 4px rgba(32,45,74,.2);
}

.select:hover {
    transition: box-shadow 250ms;
    box-shadow: 0 4px 8px rgba(32,45,74,.2);
}

.download-button {
    opacity: 1;
    transition: .3s;
}

.download-button:hover {
    opacity: .75;
}

.toggle-switch {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 700;
    color: #202d4a;
}

.toggle {
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    transition-property: opacity,background-color;
    transition-duration: 125ms;
    height:26px;
    width:42px;
    padding:2px;
    background-color: rgba(11,23,48,.1);
    box-shadow: inset 0 0 0 1px rgba(11,23,48,.1);
    border-radius: 34px;
}

.toggle::after {
    content: '';
    display: block;
    height: 22px;
    width: 22px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(11,23,48,.2),0 0 0 1px rgba(11,23,48,.1);
    border-radius: 100%;
    transition: margin-left 250ms cubic-bezier(0,1,0,1);
}

.active {
    background-color: #52cc7a;
}

.toggle.active::after {
    margin-left: 16px;
}

.select {
    background-color:#fff;
    transition: .3s ease;
}

.select:hover {
    background-color:#f2f6fa;
}
</style>