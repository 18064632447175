<template>
    <main>
    <div style="width: 100%;max-width: 1220px;margin: 0 auto;">
        
        <div v-if="user" style="margin-top:100px;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
            <div style="margin-bottom: 20px;width:480px;color:#202d4a;font-size:18px;font-weight:700;">Account</div>
            <div style="padding:40px;border-radius:8px;width:480px;background:#f2f6fa;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">
        
                <div style="margin-bottom:5px;display:flex;align-items:center;">
                    <div style="width:130px;color:#202d4a;font-size:16px;font-weight:600;">Email</div>
                    <div @click.prevent="toggleChangeEmail" class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                        <div>{{ user.email }}</div>
                    </div>
                </div>

                <div style="margin-bottom:5px;display:flex;align-items:center;">
                    <div style="width:130px;color:#202d4a;font-size:16px;font-weight:600;">Password</div>
                    <div @click.prevent="toggleChangePassword" class="setting-item" style="cursor:pointer;margin-left:20px;border-radius:6px;padding:10px 11px;">
                        <div style="font-size:22px;font-weight:600;">••••••••</div>
                    </div>
                </div>
        </div>
    </div>
        
    </div>
    <ChangeEmail v-if="changeEmailOpen" @toggleChangeEmail="toggleChangeEmail" :user="user" />
    <ChangePassword v-if="changePasswordOpen" @toggleChangePassword="toggleChangePassword" :user="user" />
    </main>
</template>

<script>
import ChangePassword from '@/components/ChangePassword.vue'
import ChangeEmail from '@/components/ChangeEmail.vue'
import { mapState } from 'vuex'
export default {
    components: {
        ChangePassword,
        ChangeEmail
    },
    data(){
        return {
            changePasswordOpen: false,
            changeEmailOpen: false
        }
    },
    computed: {
      ...mapState ({
        user: state => state.auth.user,
     }),
    },
    methods: {
       toggleChangePassword(){
           this.changePasswordOpen = !this.changePasswordOpen
       },
       toggleChangeEmail(){
           this.changeEmailOpen = !this.changeEmailOpen
       }
    }
    
}
</script>

<style scoped>
.setting-item {
    background-color: #f2f6fa;
    transition: .2s;
}
.setting-item:hover {
    background-color:#e4e9ef;
}
</style>