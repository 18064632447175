<template>
  <main v-if="loaded" style="background: rgba(242,246,250,.9);position:fixed;top:0;right:0;bottom:0;left:0;z-index:110;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
    <div style="padding:40px 0;border-radius:10px;min-width:300px;min-height:200px;background:#fff;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">

        <div style="padding:25px;min-width:500px;max-width:500px;">

                <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;">Select customer</div>
                <div style="position:relative">
                            <div style="margin-bottom:30px;display:flex;align-items:center;">
                                <div @click.prevent="toggleOptions" class="select" style="display:flex;justify-content:space-between;align-items:center;cursor:pointer;height:50px;padding:14px 20px;width:100%;background: rgba(242,246,250,.9);border-radius:6px;">
                                    <div style="color:#202d4a;font-size:14px;font-weight:600;">{{ display }}</div>
                                    <img style="height:6px;" src="https://cdn.side.nz/v/dc5d9a64-0150-47a3-9ca1-e557474bbc63.svg" />
                                </div>
                            </div>

                            <div v-if="toggleOpen" style="box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);position:absolute;top:50px;left:0;width:100%;background: rgba(242,246,250);z-index:300;">
                                <div v-for="customer in customers" :key="customer._id" @click.prevent="selectCustomer(customer)" class="select" style="cursor:pointer;padding:15px 20px;">{{ customer.name }}</div>
        
                            </div>
                        </div>

                        <div style="margin-bottom:15px;display:flex;flex-direction:column;">
                            <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;">Integration name</div>
                            
                            <div style="display:flex;align-items:center;border-radius:6px;">
                                    <input v-model="fields.name" class="input-field" style="border:none;display:flex;justify-content:center;align-items:center;height:50px;padding:14px 20px;width:100%;background-color:rgba(242,246,250,.9);border-radius:6px;font-size:14px;font-weight:600;" type="text" /> 
                            </div>
                        </div>
                
                <div @click.prevent="addIntegration" style="padding:20px;cursor:pointer;text-align:center;color:#202d4a;font-size:18px;font-weight:600;">Apply</div>
  
        </div>

        </div>
  </main>
</template>

<script>
import { fetch } from '@/helpers'
import { mapState } from 'vuex'
export default {
    data(){
        return {
            toggleOpen: false,
            loaded: false,
            fields: {},
            display: ''
        }
    },
    computed: {
      ...mapState ({
        customers: state => state.customers.items,
        user: state => state.auth.user
     }),
   },
    created(){
       
        if(this.$route.query.code){
            if(!this.user){
                var cookie = {
                    redirect: true,
                    code: this.$route.query.code || '',
                    link: 'auth'
                }
                this.$cookies.set('redirect', cookie, '10min')
                window.open(`/login`, '_self')
            } else {
                this.$store.dispatch('customers/read')
                .then(() => {
                this.loaded = true
                })
            }
        } else {
            window.location.href = '/'
        }
    },
    methods: {
        toggleOptions(){
            this.toggleOpen = !this.toggleOpen
        },
        selectCustomer(customer){
            this.toggleOpen = false
            this.fields.customer = customer._id
            this.display = customer.name
            this.fields.code = this.$route.query.code
            this.fields.type = 'pipedrive'
        },
        addIntegration(){
            if(this.fields.customer && this.fields.name) {
                fetch('crms/authenticate', this.fields)
                .then(() => {
                    window.open(`/customer/${this.fields.customer}`, '_self')
                })
                .catch((err) => this.$toast(err))
            } else {
               this.$toast('Fields cant be empty')
            }
        }
    }

}
</script>

<style scoped>
</style>
