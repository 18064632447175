import axios from 'axios'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

const URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {
    items: [],
    item: {}
  },
  actions: {
    read({ commit }, cusId){
      commit('setItem', {resource: 'crms', items: []}, {root: true})
      return axiosInstance.get(`${URL}/crm/${cusId}`)
      .then(res => {
        const crms = res.data
        commit('setItems', {resource: 'crms', items: crms}, {root: true})
        return true
      })
    },
    priority({state, commit}, data) {
      return axiosInstance.patch(`${URL}/crm/priority`, data)
        .then(res => {
          const updatedCrm = res.data
          const index = state.items.findIndex(crm => crm._id === updatedCrm._id)
          if (index > -1) {
            commit('updatePriority', { index: index, priority: updatedCrm.priority })
          }
          return true
        })
        .catch(err => rejectError(err))
    },
    authenticate(_, data){
      return axios.post(`${URL}/auth/authenticate`, data)
        .catch(err => rejectError(err))
    },
    createcrm({state, commit}, data) {
      return axiosInstance.post(`${URL}/crm/create`, data)
        .then(res => {
          var createdCrm = res.data
          const index = state.items.length
          commit('addItemToArray', {item: createdCrm, index: index})
          return true
        })
        .catch(err => rejectError(err))
    },
    delete({commit, state}, crmId) {
      return axiosInstance.delete(`${URL}/crm/delete/${crmId}`)
        .then((res) => {
          const id = res.data
          const index = state.items.findIndex(crm => crm._id === id)
          if (index > -1) {
            commit('deleteCrm', { index: index })
          }
        })
      .catch(err => rejectError(err))
    },
  },
  mutations: {
    deleteCrm(state, {index}) {
      state.items.splice(index, 1)
    },
    updatePriority(state, data){
      state.items[data.index].priority = data.priority
    },
    addItemToArray(state, data){
      state.items.push(data.item)
    }
  }
}
