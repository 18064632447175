//import Vue from 'vue';
import { createStore } from "vuex"
//import Vuex from 'vuex'

import auth from './modules/auth'
import customers from './modules/customers'
import crms from './modules/crms'

//Vue.use(Vuex)

export default createStore({
  modules: {
    auth,
    customers,
    crms
  },
  mutations: {
    setItems (state, { resource, items}) {
      state[resource].items = items
    },
    setItem (state, { resource, item}) {
      state[resource].item = item
    }
  }
})
