// import Vue from 'vue'
import { createWebHistory, createRouter } from 'vue-router';
//import Router from 'vue-router'
import store from '@/store'

import Dashboard from '@/pages/Dashboard'
import Settings from '@/pages/Settings'
import CustomerDetail from '@/pages/CustomerDetail'
import Login from '@/pages/Login'
import Redirect from '@/pages/Redirect'
import Auth from '@/pages/Auth'
// import Register from '@/pages/Register'
import NotFound from '@/pages/NotFound'


//Vue.use(Router)

const routes = [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/customer/:id',
      name: 'CustomerDetail',
      component: CustomerDetail,
      meta: {onlyAuthUser: true}
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {onlyGuestUser: true}
    },
    {
      path: '/redirect',
      name: 'Redirect',
      component: Redirect
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Auth
    },
    // {
    //   path: '/register',
    //   name: 'Register',
    //   component: Register,
    //   meta: { onlyGuestUser: true }
    // },
    {
      path: '/*',
      name: 'NotFound',
      component: NotFound
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

  router.beforeEach((to, _, next) => {
    store.dispatch('auth/getAuthUser')
      .then(() => {
        const isAuthenticated = store.getters['auth/isAuthenticated']

        if (to.meta.onlyAuthUser) {
          if (isAuthenticated) {
            next()
          } else {
            next({name: 'Login'})
          }
        } else if (to.meta.onlyGuestUser) {
          if (isAuthenticated) {
            next({name: 'Dashboard'})
          } else {
            next()
          }
        } else {
          next()
        }
      })
  })

export default router
