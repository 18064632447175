<template>
    <main>
    <div v-if="loaded" style="width: 100%;max-width: 1220px;margin: 0 auto;">
        <div style="margin: 60px 10px;display:flex;width: 260px;justify-content:space-between;align-items:center;">
            <div style="color:#202d4a;font-size:18px;font-weight:700;">Customers</div>
            <div @click.prevent="toggleAddCustomer" style="background-color: #f2f6fa;padding: 5px 12px;border-radius:6px;cursor:pointer;">
              <div style="color:#9096a5;font-size:15px;font-weight:600;">Add Customer</div>
            </div>
        </div>

        <div>
            <div style="display:flex;align-items:center;width:100%;border-bottom:2px solid #f2f6fa;padding:20px 10px;">
                <div style="width:250px;color:#202d4a;font-size:18px;font-weight:700;">Name</div>
                <div style="width:400px;color:#202d4a;font-size:18px;font-weight:700;">3CX Server</div>
                <div style="flex:1;margin-left:30px;width:100px;color:#202d4a;font-size:18px;font-weight:700;">Added</div>
            </div>

            <div v-for="customer in customers" :key="customer._id">
                <router-link :to="{ name: 'CustomerDetail', params: { id: customer._id } }" class="list-item" style="display:flex;align-items:center;width:100%;border-bottom:2px solid #f2f6fa;padding:30px 10px;">
                    <div style="width:250px;color:#202d4a;font-size:18px;font-weight:700;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{ customer.name }}</div>
                    <div style="width:400px;color:#202d4a;font-size:18px;font-weight:700;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{ customer.server }}</div>
                    <div style="flex:1;margin-left:30px;width:100px;color:#202d4a;font-size:18px;font-weight:700;">{{ customer.createdAt | formatDate }}</div>
                </router-link>
            </div>

        </div>
    
    </div>
        <AddCustomer v-if="addCustomerOpen" @toggleAddCustomer="toggleAddCustomer"/>
    </main>
</template>

<script>
import AddCustomer from '@/components/AddCustomer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        AddCustomer
    },
    data(){
        return {
            addCustomerOpen: false,
            loaded: false
        }
    },
    computed: {
      ...mapState ({
        customers: state => state.customers.items
     }),
   },
   created(){
    this.$store.dispatch('customers/read')
    .then(() => {
      this.loaded = true
    })
  },
    methods: {
        toggleAddCustomer(){
            this.addCustomerOpen = !this.addCustomerOpen
        }
    }
    
}
</script>

<style scoped>
    .list-item {
        background-color:#fff;
        transition: .2s;
        cursor:pointer;
    }

    .list-item:hover {
        background-color:#f2f6fa;
    }
</style>