<template>
    <div style="background: rgba(242,246,250,.9);position:fixed;top:0;right:0;bottom:0;left:0;z-index:110;height:100%;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;">
        <div v-click-away="toggleAddCustomer" style="padding:40px;border-radius:10px;width:410px;min-height:258px;background:#fff;display:flex;flex-direction:column;justify-content:center;box-shadow: 0 10px 25px 0 rgba(60,63,64,.14);">
       
        <div style="margin-bottom:25px;">
            <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;">Name</div>
            <input v-model="form.name" type="text" placeholder="Example Ltd" maxlength="50" style="font-size:14px;font-weight:500;padding: 10px 12px;height:45px;border-radius:5px;border:none;background-color:#f2f6fa;width:100%;" />
        </div>

        <div style="margin-bottom:30px;">
            <div style="color:#8d95a6;font-size:14px;margin-bottom:5px;">3CX server url</div>
            <input v-model="form.server" type="text" placeholder="https://example.3cx.co.nz:5001/" maxlength="50" style="font-size:14px;font-weight:500;padding: 10px 12px;height:45px;border-radius:5px;border:none;background-color:#f2f6fa;width:100%;" />
        </div>

        <div style="align-self:center;">
            <div @click.prevent="create" style="cursor:pointer;max-width:150px;height:40px;border-radius:5px;background-color:#fff;color:#202d4a;font-size:18px;font-weight:600;display:flex;align-items:center;justify-content:center;">
                <div>Add</div>
            </div>
        </div>

        </div>
    </div>
</template>

<script>
import { fetch } from '@/helpers'
export default {
    data(){
        return {
            form: {
                name: '',
                server: ''
            }
        }
    },
    methods: {
        toggleAddCustomer(){
          this.$emit('toggleAddCustomer')  
        },
        create() {
            fetch('customers/create', this.form)
            .then(() => {
                this.$emit('toggleAddCustomer')
                this.$toast('Customer created')
            })
            .catch((err) => this.$toast(err))
        }
    }
}
</script>

<style scoped>
    
</style>